	import React, { Component } from "react";

import "../css/sharan-style.css";
import PopupForm from "./popupForm";

//import footer_logo from "../images/footer-logo.svg";

import InstagramIcon from '../images/icons/instagram.svg';
import FacebookIcon from '../images/icons/facebook-f.svg';
import YoutubeIcon from '../images/icons/youtube.svg';
import LinkedinIcon from '../images/icons/linkedin.svg';
//import LocationDotIcon from '../images/icons/location-dot-solid.svg';
import LocationDotIcon from '../images/icons/location.png';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
		};
	}
    

	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};
    handleCTAClick = () => {
		localStorage.setItem('isPopupShown', 'Never');
	};

onfootOurPartnerLink = (e) => {
		var check = 0;
			const utabHead = e.target.parentNode;
			if (utabHead) {
                if(utabHead.classList.contains("active")){
                    utabHead.classList.remove("active");
                }else{
                    utabHead.classList.add("active");
                }	
            }
		
	};

	render() {

        //console.log("this.props?.admissionsMenu->"+JSON.stringify(this.props?.listUniversities));



        /* const uniArr = this.props.data.allWordpressWpUniversities.nodes;*/
        let usa = [];
        let uk = [];
        let australia = [];
        let others = [];
        const data = this.props?.listUniversities; 
        
        data.forEach((item) => {
          const country = item.acf.country;
          if (country === 'United States of America') {
            usa.push(item);
          } else if (country === 'United Kingdom') {
            uk.push(item);
          } else if (country === 'Australia') {
            australia.push(item);
          } else {
            others.push(item);
          }
        });
        
        
        let blogaustralia = [];
        let blogusa = [];
        let bloguk = [];
        let blogother = [];
        const footblogdata = this.props?.footerlistblog; 

        
        footblogdata.forEach((item) => {
            const foot_blog_category = item.node.acf.footer_blog_category;
            if (foot_blog_category === 'Studying in Australia') {
                blogaustralia.push(item.node);
            } else if (foot_blog_category === 'Studying in The USA') {
                blogusa.push(item.node);
            } else if (foot_blog_category === 'Studying in The UK') {
                bloguk.push(item.node);
            } else {
                blogother.push(item.node);
            }
          });

        
		return (
            
			<div class="newStyle">
				<PopupForm time={this.state.time} />
				
        <footer id="footer" class="footer-dark bg-fast-blue pb-0 pt-0">
        <div id="footUniHolder" class="footer-bottom padding-three-tb border-bottom border-color-white-transparent lg-padding-three-tb md-padding-50px-tb">
                <div id="footOurPartnerBlock" class="container">
                    <a id="footOurPartnerLink" onClick={(e) => this.onfootOurPartnerLink(e)} class="foot-block-title min-17px-1200" >Our Partner Institutions</a>
                
                    <div class="footOurPartnerDetail text-left">
                    <div class="row row-cols-1 row-cols-sm-2 justify-content-center">
                        <div class="col-12 col-xl-3 col-lg md-margin-50px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">Australia</span>
                            <ul>

                            {australia.map((item) => {
												return (
													<li key={item.id}>
														<a href={'/university-detail/'+item.slug}>{item.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}</a>
													</li>
												);
											})}
                            </ul>
                        </div>
                        <div class="col-12 col-xl-3 col-lg md-margin-50px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">UK</span>
                            <ul class="footer-link-menu">
                            {uk.map((item) => {
												return (
													<li key={item.id}>
														<a href={'/university-detail/'+item.slug}>{item.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}</a>
													</li>
												);
											})}
                            </ul>
                        </div>
                    
                        <div class="col-12 col-xl-3 col-lg md-margin-50px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">USA</span>
                            <ul>
                            {usa.map((item) => {
												return (
													<li key={item.id}>
														<a href={'/university-detail/'+item.slug}>{item.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}</a>
													</li>
												);
											})}
                            </ul>
                        </div>

                        <div class="col-12 col-xl-3 col-lg md-margin-50px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">Rest of the World</span>
                            <ul>
                            {others.map((item) => {
												return (
													<li key={item.id}>
														<a href={'/university-detail/'+item.slug}>{item.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}</a>
													</li>
												);
											})}
                            </ul>
                        </div>

                        <div class="col-12">                       
                            <p><br/><br/>CRICOS Provider Nos. : Flinders University 00114A | Griffith University 00233E | James Cook University 00117J | La Trobe University 00115M | University of Wollongong 00102E | University of Wollongong College 02723D</p>
                            {/*Swinburne University of Technology 00111D  |    this should be thirdlast */}
                        </div>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-top padding-40px-tb border-bottom border-color-white-transparent mb-0">
                <div class="container"> 
                    <div class="row align-items-center">
                        <div class="col-12 col-md-3  text-sm-start sm-margin-20px-bottom">
                            <a href="/" class="footer-logo"><img src="https://assets.theworldgrad.com//gatsby-assets/images/logo-white.png" data-at2x="https://assets.theworldgrad.com//gatsby-assets/images/logo-white.png" alt="The WorldGrad" width="200" height="57" /></a>
                        </div>
                        <div class="col-12 col-md-6 text-md-center sm-margin-20px-bottom">
                            <span class="alt-font font-weight-600 d-inline-block align-middle margin-5px-right text-uppercase text-white min-17px-1200">The Smartest Way To Study Overseas</span>
                        </div>
                        <div class="col-12 col-md-3 text-center text-md-end">
                            <div class="social-icon-style-12">
                                <ul class="extra-small-icon light">
                                <li>
   <a class="facebook" href="https://www.facebook.com/The-WorldGrad-104434275220410/" target="_blank">
      <img src={FacebookIcon} alt="Icon" className="text-extra-large newicon newicon-small" />
   </a>
</li>
<li>
   <a class="instagram" href="https://www.instagram.com/theworldgrad/" target="_blank">
      <img src={InstagramIcon} alt="Icon" className="text-extra-large newicon newicon-social" />
   </a>
</li>
<li>
   <a class="linkedin" href="https://www.linkedin.com/company/the-worldgrad/mycompany/" target="_blank">
      <img src={LinkedinIcon} alt="Icon" className="text-extra-large newicon newicon-social" />
   </a>
</li>
<li>
   <a class="instagram" href="https://www.youtube.com/channel/UCc_ezIRLC6YKiUXV4rhgrog" target="_blank">
      <img src={YoutubeIcon} alt="Icon" className="text-extra-large newicon newicon-large newicon-social newicon-social-large" />
   </a>
</li> </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="footer-bottom padding-three-tb border-bottom border-color-white-transparent lg-padding-three-tb md-padding-50px-tb">
                <div class="container">
                    <div class="row row-cols-1 row-cols-sm-2 justify-content-center">
                        <div class="col-12 col-xl-2 col-lg md-margin-50px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">{this.props?.exploreMenu?.name}</span>
                            <ul>
                               {this.props?.exploreMenu?.items?.map((item) => {
                                return (
                                <li key={item.object_id}>
                                    <a href={item.url}>{item.title}</a>
                                </li>
                                );
                                })}
                            </ul>
                        </div>
                        <div class="col-12 col-xl-3 col-lg md-margin-50px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">{this.props?.hybridProgramsMenu?.name}</span>
                            <ul class="footer-link-menu">
                                {this.props?.hybridProgramsMenu?.items?.map((item) => {
												return (
													<li key={item.object_id}>
														<a href={item.url}>{item.title}</a>
													</li>
												);
											})}
                            </ul>
                        </div>
                    
                        <div class="col-12 col-xl-2 col-lg md-margin-50px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">{this.props?.admissionsMenu?.name}</span>
                            <ul>
                            {this.props?.admissionsMenu?.items?.map((item) => {
												return (
													<li key={item.object_id}>
														<a href={item.url}>{item.title}</a>
													</li>
												);
											})}
                            </ul>
                        </div>

                        <div class="col-12 col-xl-2 col-lg md-margin-50px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">{this.props?.countriesMenu?.name}</span>
                            <ul>
                            {this.props?.countriesMenu?.items?.map((item) => {
												return (
													<li key={item.object_id}>
														<a href={item.url}>{item.title}</a>
													</li>
												);
											})}
                            </ul>
                        </div>
                        
                        <div class="col-12 col-xl-3  last-paragraph-no-margin">                       
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom">{this.props?.offeringsMenu?.name}</span>
                            <ul class="footer-link-menu">{this.props?.offeringsMenu?.items?.map((item) => {
												return (
													<li key={item.object_id}>
														<a href={item.url}>{item.title}</a>
													</li>
												);
											})}</ul>
                        </div>
                        
                    </div>
                </div>

            </div> 
			<div class="footer-bottom padding-three-tb lg-padding-three-tb md-padding-50px-tb text-sm-left border-bottom border-color-white-transparent">
                <div class="container  text-left-md">
                    <div class="row row-cols-1 row-cols-sm-12 justify-content-center">
                        <div class="col-12 col-xl-4 col-lg margin-30px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">
                            <img src={LocationDotIcon} alt=">" height="24" width="24" className="text-extra-large line-icon-Location-2 icon-small align-middle text-white" />
Mumbai: The WorldGrad HQ</span>
                            <p>91Springboard, Godrej Business Park,<br />
LBS Marg, Vikhroli (W) – 400079</p>
                        </div>
						<div class="col-12 col-xl-4 col-lg margin-30px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">
                            <img src={LocationDotIcon} alt=">" height="24" width="24" className="text-extra-large line-icon-Location-2 icon-small align-middle text-white" /> Delhi</span>
                            <p>SpaceTime, Savitri Cinema Complex, 3rd Floor, DLF Centre,

Greater Kailash II, New Delhi – 110048</p>
                        </div>
						<div class="col-12 col-xl-4 col-lg margin-30px-bottom xs-margin-25px-bottom">
                            <span class="alt-font font-weight-700 d-block text-white margin-10px-bottom xs-margin-10px-bottom">
                            <img src={LocationDotIcon} alt=">" height="24" width="24" className="text-extra-large  line-icon-Location-2 icon-small align-middle text-white" /> Hyderabad</span>
                            <p>91Springboard, LVS Arcade, Madhapur, 71 Jubilee Enclave, HITEC City, Hyderabad – 500081</p>
                        </div>
						
						<div class="col-12 col-lg margin-0px-bottom xs-margin-25px-bottom">
                            
                            <p class="mb-0">We are present in Bengaluru & Chandigarh too!</p>
                        </div>
						
                    </div>
                </div>
            </div>
			<div class="d-none footer-bottom padding-two-tb lg-padding-two-tb md-padding-50px-tb border-bottom border-color-white-transparent">
                <div id="footOurPartnerBlock" class="container">
                    <a id="footOurPartnerLink" onClick={(e) => this.onfootOurPartnerLink(e)} class="foot-block-title" >Study Abroad Resources</a>
                
                    <div class="footOurPartnerDetail">
                        <div class="row justify-content-center">
                            <div class="col col-xl-11 col-lg">
                                <p class="mb-0 footer-blog-list">
                                        <strong class="font-weight-600 text-white">Studying in The USA: </strong>{blogusa.map((item) => {return (<span><a href={'/study-resources/'+item.slug}>{item.acf.footer_blog_title}</a></span>);})}  
                                </p>
                                
                                <p class="mb-0 footer-blog-list">
                                        <strong class="font-weight-600 text-white">Studying in Australia: </strong>{blogaustralia.map((item) => {return (<span><a href={'/study-resources/'+item.slug}>{item.acf.footer_blog_title}</a></span>);})}  
                                </p>
                                
                                <p class="mb-0 footer-blog-list">
                                        <strong class="font-weight-600 text-white">Studying in The UK: </strong>{bloguk.map((item) => {return (<span><a href={'/study-resources/'+item.slug}>{item.acf.footer_blog_title}</a></span>);})} 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<div class="footer-bottom padding-one-tb lg-padding-one-tb md-padding-20px-tb border-bottom border-color-white-transparent">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col col-xl-12 col-lg">
                           <p class="mb-0"><strong class="font-weight-600 text-white">Phone: </strong><a href="tel:080-45681763">080-45681763</a> <span class="d-none d-sm-inline">&nbsp; | &nbsp;</span> <br class="d-none d-xs-block" /><strong class="font-weight-600 text-white">Partnership Queries: </strong><a href="mailto:partnerships@theworldgrad.com">partnerships@theworldgrad.com</a> <span class="d-none d-sm-inline">&nbsp; | &nbsp;</span><br class="d-none d-xs-block" /> <strong class="font-weight-600 text-white">Student Admissions: </strong><a href="mailto:enquiries@theworldgrad.com">enquiries@theworldgrad.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
			
			<div class="footer-bottom padding-two-tb lg-padding-two-tb md-padding-50px-tb border-top border-color-white-transparent">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col col-xl-12 col-lg ">
                           <p class="mb-0">©LINC Education 2023. All Rights Reserved. | <a href="/privacypolicy">Privacy Policy</a> | <a href="/terms-and-conditions">Terms &amp; Conditions</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="whatsapp">   <a href="https://api.whatsapp.com/send?phone=+919004334313&text=Hey, I have a query regarding studying abroad&source=website" onClick={() => this.handleCTAClick()} target="_blank">
		<img  src="https://assets.theworldgrad.com//wp-content/uploads/2023/03/icons8-whatsapp-96.png" alt="WhatsApp Link" />
	</a> </div>
          
				</footer>

			</div>
		);
	}
}
export default Footer;

